// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  url: 'https://moppa-system.uc.r.appspot.com',
  firebaseConfig: {
    apiKey: "AIzaSyBIcvqFdyFceWOdjNnejgBd19siDpTbjPo",
    authDomain: "moppa-system.firebaseapp.com",
    databaseURL: "https://moppa-system.firebaseio.com",
    projectId: "moppa-system",
    storageBucket: "moppa-system.appspot.com",
    messagingSenderId: "890358165682",
    appId: "1:890358165682:web:9204b941b2b41d702459ab",
    measurementId: "G-792DW2CQ9C"
  },
  endpoints: {
    login: '/login/',
    users: '/users/',
    services: '/services/',
    tariffsName: '/services/tariffs/names',
    tarrifs: '/services/tariffs/',
    documents: '/documents/'
  },
  extensions: {
    filter: 'filter',
    request: 'request',
    users: 'users', 
    services: 'services'
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
