import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

// Layout
import { ContentLayoutComponent } from './shared/components/layout/content-layout/content-layout.component';

// Guards
import { AdminGuard } from './core/guards/roles/admin.guard';
import { ClientGuard } from './core/guards/roles/clients.guard';
import { UnAuthGuard } from './core/guards/un-auth/un-auth.guard';

const routes: Routes = [
  {
    path: 'admin',
    component: ContentLayoutComponent,
    loadChildren: () => import('./pages/admin/pages.module').then(m => m.PagesModule),
    canActivate: [AdminGuard]
  },
  {
    path: 'account-service',
    loadChildren: () => import('./account-service/account-service.module').then(m => m.AccountServiceModule),
    canActivate: [ClientGuard]
  },
  {
    path: '',
    loadChildren: () => import('./account/account.module').then(m => m.AccountModule),
    canActivate: [UnAuthGuard],
  },
  {
    path: '**',
    pathMatch: 'full',
    redirectTo: ''
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
