import { Injectable } from '@angular/core';
import { environment } from '../../../../environments/environment';
import { HttpClient } from '@angular/common/http';

// Libs
import { CookieService } from 'ngx-cookie-service';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private cookieService: CookieService
  ) { }

  isLogged() {
    const uid = this.cookieService.get('uid');
    const role = this.cookieService.get('role');
    const info = {uid, role}; 
    return info;
  }

  login(email: string, password: string) {
    const userCredentials = { email, password };
    return this.http.post(`${environment.url + environment.endpoints.login }`, userCredentials);
  }

  logout() {
    this.cookieService.deleteAll();
  }

}
