<!-- Page Header Start-->
<div class="page-main-header" [ngClass]="{'open' : navServices.collapseSidebar}" id="page-main-header">
  <div class="main-header-right row">
    <div class="main-header-left d-lg-none">
      <!-- <div class="logo-wrapper"><a href="javascript::void(0)"><img src="assets/images/endless-logo.png" alt=""></a>
      </div> -->
    </div>
    <div class="mobile-sidebar">
      <div class="media-body text-right switch-sm">
        <label class="switch">
          <a href="javascript:void(0)" (click)="collapseSidebar()">
            <app-feather-icons id="sidebar-toggle" [icon]="'align-left'"></app-feather-icons>
          </a>
        </label>
      </div>
    </div>
    <div class="nav-right col">
      <ul class="nav-menus" [ngClass]="{'open' : openNav}">
        <li>
        </li>
        <li class="onhover-dropdown">
          <a class="txt-dark" href="javascript:void(0)">
            <h6>ES</h6>
          </a>
          <ul class="language-dropdown onhover-show-div p-20">
            <li><a href="javascript:void(0)" data-lng="es"><i class="flag-icon flag-icon-um"></i>Inglés</a></li>
            <li><a href="javascript:void(0)" data-lng="fr"><i class="flag-icon flag-icon-nz"></i>Francés</a></li>
          </ul>
        </li>
        <li class="onhover-dropdown">
          <app-feather-icons [icon]="'bell'"></app-feather-icons><span class="dot"></span>
          <ul class="notification-dropdown onhover-show-div">
            <li>Notification <span class="badge badge-pill badge-primary pull-right">3</span></li>
            <li>
              <div class="media">
                <div class="media-body">
                  <h6 class="mt-0"><span>
                      <app-feather-icons class="shopping-color" [icon]="'shopping-bag'"></app-feather-icons>
                    </span>Servicio Completado..!<small class="pull-right">9:00 AM</small></h6>
                  <p class="mb-0">Servicio Completado en Zona 15.</p>
                </div>
              </div>
            </li>
            <li class="bg-light txt-dark"><a href="javascript:void(0)">Todas</a> las Notificaciones</li>
          </ul>
        </li>
        <li class="onhover-dropdown">
          <div class="media align-items-center"><img class="align-self-center pull-right img-50 rounded-circle"
              src="assets/images/dashboard/user.png" alt="header-user">
          </div>
          <ul class="profile-dropdown onhover-show-div p-20">
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'user'"></app-feather-icons>Editar Perfil
              </a></li>
            <li><a href="javascript:void(0)">
                <app-feather-icons [icon]="'settings'"></app-feather-icons>Configuración
              </a></li>
            <li (click)="logout()"><a href="javascript:void(0)">
                <app-feather-icons [icon]="'log-out'"></app-feather-icons>Cerrar Sesión
              </a></li>
          </ul>
        </li>
      </ul>
      <div class="d-lg-none mobile-toggle pull-right">
        <app-feather-icons [icon]="'more-horizontal'"></app-feather-icons>
      </div>
    </div>
  </div>
</div>
<!-- Page Header Ends -->